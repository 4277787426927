import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  createAlert,
  deleteAlert,
  deleteMultipleAlert,
  getAlertByID,
  getAlertByTargetID,
  getAlertSearchSuggestion,
  getAlertSummary,
  getAlerts,
  updateAlert,
  patchAlert,
  getRegisteredChannels,
  deleteAlertByCustomViewId,
  registerTeamsWebHook,
  updateTeamsWebHook,
  testTeamsWebHook,
  deleteTeamsWebHook,
} from '../services/alerts';

export const useTeamsWebhookChannels = () => {
  return useQuery('registeredTeamsChannels', () => getRegisteredChannels());
};

export const useAlertsAPI = () => {
  const queryClient = useQueryClient();

  const createAlertMutation = useMutation(createAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertsMutation = useMutation(getAlerts, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertByIDMutation = useMutation(getAlertByID, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertByTargetIDMutation = useMutation(getAlertByTargetID, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertSummaryMutation = useMutation(getAlertSummary, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertSearchSuggestionMutation = useMutation(getAlertSearchSuggestion, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const updateAlertMutation = useMutation(updateAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const patchAlertMutation = useMutation(patchAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const deleteAlertMutation = useMutation(deleteAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const deleteMultipleAlertMutation = useMutation(deleteMultipleAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getRegisteredChannelsMutation = useMutation(getRegisteredChannels, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });
  // const useAlertSearchSuggestion = (params: any) =>
  //   useQuery(['searchSuggestion', params], () => getAlertSearchSuggestion(params));

  const deleteAlertByCustomViewMutation = useMutation(deleteAlertByCustomViewId, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const registerTeamsWebHookMutation = useMutation(registerTeamsWebHook, {
    onSuccess: () => queryClient.invalidateQueries('registeredTeamsChannels'),
  });

  const updateTeamsWebHookMutation = useMutation(updateTeamsWebHook, {
    onSuccess: () => queryClient.invalidateQueries('registeredTeamsChannels'),
  });

  const deleteTeamsWebHookMutation = useMutation(deleteTeamsWebHook, {
    onSuccess: () => queryClient.invalidateQueries('registeredTeamsChannels'),
  });

  const testTeamsWebHookMutation = useMutation(testTeamsWebHook);

  return {
    createAlertMutation,
    getAlertsMutation,
    getAlertByIDMutation,
    getAlertByTargetIDMutation,
    getAlertSummaryMutation,
    getAlertSearchSuggestionMutation,
    updateAlertMutation,
    patchAlertMutation,
    deleteAlertMutation,
    deleteMultipleAlertMutation,
    getRegisteredChannelsMutation,
    deleteAlertByCustomViewMutation,
    registerTeamsWebHookMutation,
    deleteTeamsWebHookMutation,
    updateTeamsWebHookMutation,
    testTeamsWebHookMutation,
    // useAlertSearchSuggestion,
  };
};
