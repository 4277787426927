import React from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';

import { chartDarkTheme } from '../../../../theme/component-themes/chart-theme';

import { TrendIcon } from './styles';

import { ICONS } from '@controlrooms/constants';
import { Mode, TooltipData, TimeSeries, TimeSeriesString } from '@controlrooms/models';
import {
  yAccessor,
  yAccessorMax,
  yAccessorMin,
  yAccessorString,
  yAccessorUom,
} from '@controlrooms/utils';

export const conditionalLimitTooltipValue = (
  timeSeries: TooltipData,
  currentLimitOnHover?: Mode,
  isStringValuesChart?: boolean,
) => {
  const minVal = yAccessorMin(timeSeries);
  const maxVal = yAccessorMax(timeSeries);

  const limitMode = currentLimitOnHover?.type;
  const limitValue = currentLimitOnHover?.value;
  const limitCondition = currentLimitOnHover?.limitCondition;

  let isLow,
    isHigh,
    isHighHighs,
    isLowLows = false;

  if (limitMode === 'lows') isLow = Number(limitValue) > Number(minVal);
  if (limitMode === 'lowLows') isLowLows = Number(limitValue) > Number(minVal);
  if (limitMode === 'highs') isHigh = Number(maxVal) > Number(limitValue);
  if (limitMode === 'highHighs') isHighHighs = Number(maxVal) > Number(limitValue);

  const displayString = isStringValuesChart || !Number(yAccessor(timeSeries));
  const renderedMinValue =
    isStringValuesChart || !Number(minVal) ? yAccessorString(timeSeries) : minVal;
  const renderedMaxValue =
    isStringValuesChart || !Number(maxVal) ? yAccessorString(timeSeries) : maxVal;

  return renderToString(
    <div>
      <div>
        {timeSeries.value !== null ? (
          <>
            {displayString ? (
              <span>{yAccessorString(timeSeries)}</span>
            ) : (
              <span>{yAccessor(timeSeries)}</span>
            )}
            <span className="uom-display">{yAccessorUom(timeSeries)}</span>
          </>
        ) : (
          <span>{'-'}</span>
        )}
        <span style={{ marginLeft: 10 }} className={`min ${isLow || isLowLows ? 'warning' : ''}`}>
          <TrendIcon
            className={isLow || isLowLows ? 'warning' : ''}
            name={ICONS.TooltipArrow}
            width="8"
            height="7"
            color={
              isLow || isLowLows
                ? chartDarkTheme.tooltip.warningColor
                : chartDarkTheme.tooltip.minMaxIconColor
            }
          />
          {renderedMinValue === null ? '-' : renderedMinValue}
        </span>
        <span
          style={{ marginLeft: 10 }}
          className={`max ${isHigh || isHighHighs ? 'warning' : ''}`}
        >
          <TrendIcon
            name={ICONS.TooltipArrow}
            rotate={180}
            className={isHigh || isHighHighs ? 'warning' : ''}
            width="8"
            height="7"
            color={
              isHigh || isHighHighs
                ? chartDarkTheme.tooltip.warningColor
                : chartDarkTheme.tooltip.minMaxIconColor
            }
          />
          {renderedMaxValue === null ? '-' : renderedMaxValue}
        </span>
      </div>
      <div>
        <span>State: {limitCondition}</span>
      </div>
    </div>,
  );
};

export const tooltipValue = (timeSeries: TooltipData, isStringValuesChart?: boolean) => {
  const displayString = isStringValuesChart || !Number(yAccessor(timeSeries));
  return renderToString(
    <>
      {timeSeries.value !== null ? (
        <>
          {displayString ? (
            <span>{yAccessorString(timeSeries)}</span>
          ) : (
            <span>{yAccessor(timeSeries)}</span>
          )}
          <span className="uom-display">{yAccessorUom(timeSeries)}</span>
        </>
      ) : (
        <span>{'-'}</span>
      )}
    </>,
  );
};

export const tooltipMinMax = (
  timeSeries: TooltipData,
  limits?: Mode[],
  isStringValuesChart?: boolean,
  // matchedSeries?: Mode[],
) => {
  const highs = limits?.filter((l) => l.type === 'highs')[0]?.value;
  const lows = limits?.filter((l) => l.type === 'lows')[0]?.value;
  const highHighs = limits?.filter((l) => l.type === 'highHighs')[0]?.value;
  const lowLows = limits?.filter((l) => l.type === 'lowLows')[0]?.value;

  const minVal = yAccessorMin(timeSeries);
  const maxVal = yAccessorMax(timeSeries);

  const renderedMinValue =
    isStringValuesChart || !Number(minVal) ? yAccessorString(timeSeries) : minVal;
  const renderedMaxValue =
    isStringValuesChart || !Number(maxVal) ? yAccessorString(timeSeries) : maxVal;

  const isLow = Number(lows) > Number(minVal);
  const isHigh = Number(maxVal) > Number(highs);
  const isHighHighs = Number(maxVal) > Number(highHighs);
  const isLowLows = Number(lowLows) > Number(minVal);

  // let limitValueText = '';
  // if (matchedSeries && matchedSeries.length > 0) {
  //   limitValueText = matchedSeries
  //     .map(({ type, value }) => `${generateAbbreviation(type)}: ${value}`)
  //     .join(', ');
  // }

  if (isStringValuesChart || timeSeries.value === null) {
    return renderToString(<></>);
  }

  return renderToString(
    <>
      <span className={`min ${isLow || isLowLows ? 'warning' : ''}`}>
        <TrendIcon
          className={isLow || isLowLows ? 'warning' : ''}
          name={ICONS.TooltipArrow}
          width="8"
          height="7"
          color={
            isLow || isLowLows
              ? chartDarkTheme.tooltip.warningColor
              : chartDarkTheme.tooltip.minMaxIconColor
          }
        />
        {renderedMinValue === null ? '-' : renderedMinValue}
      </span>
      <span className={`max ${isHigh || isHighHighs ? 'warning' : ''}`}>
        <TrendIcon
          name={ICONS.TooltipArrow}
          rotate={180}
          className={isHigh || isHighHighs ? 'warning' : ''}
          width="8"
          height="7"
          color={
            isHigh || isHighHighs
              ? chartDarkTheme.tooltip.warningColor
              : chartDarkTheme.tooltip.minMaxIconColor
          }
        />
        {renderedMaxValue === null ? '-' : renderedMaxValue}
      </span>
      {/*{limitValueText && <span className="max warning">{limitValueText}</span>}*/}
    </>,
  );
};

// ---------- DISCRETE & STRING VALUES INFO TOOLTIP ----------- //

export const getPossibleValuesFromDiscreteData = (chartData: TimeSeriesString[]) => {
  if (chartData) {
    const values = Array.from(
      new Set(chartData.filter((item) => item.value !== null).map((item) => item.value)),
    );

    const allNumeric = values.every((value) => !isNaN(Number(value)));
    return values.sort((a, b) =>
      allNumeric
        ? Number(a) - Number(b)
        : (a as unknown as string).localeCompare(b as unknown as string),
    );
  }

  return [];
};

const StyledContent = styled.div`
  font-size: 11px;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  span {
    font-weight: bold;
  }
`;

export const InfoTooltip = ({
  chartData,
  chartUom,
}: {
  chartData: TimeSeries[];
  chartUom: string | undefined;
}) => {
  const possibleValues = getPossibleValuesFromDiscreteData(chartData);
  const noData = !possibleValues || possibleValues.length === 0;

  return (
    <StyledContent>
      <span>POSSIBLE VALUES</span>
      {noData && <p>N/A</p>}
      {possibleValues.map((el) => {
        return (
          <p>
            {chartUom} {el}
          </p>
        );
      })}
    </StyledContent>
  );
};
