export enum Role {
  TROUBLESHOOT = 'TROUBLESHOOT',
  OBSERVER = 'OBSERVER',
  EXEC = 'EXEC',
  DATA = 'DATA',
  ACCOUNT = 'ACCOUNT',
  PAYMENT = 'PAYMENT',
  ALERT = 'ALERT',
  GLOBAL_ALERT_VIEWER = 'GLOBAL_ALERT_VIEWER',
  GLOBAL_ALERT_EDITOR = 'GLOBAL_ALERT_EDITOR',
  GLOBAL_LABEL_VIEWER = 'GLOBAL LABEL VIEWER',
  GLOBAL_LABEL_EDITOR = 'GLOBAL LABEL EDITOR',
  TEAMS_ADMIN = 'TEAMS_ADMIN',
  TAG_SEARCH = 'TAG_SEARCH',
  TAG_ADVANCED_SEARCH = 'TAG_ADVANCED_SEARCH',
  TAG_SECONDARY_SEARCH = 'TAG_SECONDARY_SEARCH',
  AUTO_LABEL_VIEWER = 'AUTO_LABEL_VIEWER',
}
