import { useAuth0 } from '@auth0/auth0-react';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { InfiniteData } from 'react-query';
import { useTheme } from 'styled-components';

import { useAnalytics } from '../../../app/analytics';
import { Role } from '../../../app/constants/auth';
import { monitorConfig } from '../../../app/constants/page-configs';
import { useRoles } from '../../../app/context/authorization-context';
import { AnalyzeV2 } from '../../../app/pages/routes';
import { useLayoutContext } from '../../context/layout-context';
import { MonitorContext } from '../../context/monitor-context';
import { useMonitorFilteringContext } from '../../context/monitor-filtering-context';
import { useViewContext } from '../../context/view-context';
import { useTenants } from '../../hooks/tenants';
import {
  handleTooltipMouseMove,
  handleTooltipMouseOut,
  handleTooltipMouseOver,
} from '../analyze-charts/utils/tooltips';

import { StyledChart } from './styles';

import {
  AnomaliesResult,
  Anomaly,
  LabeledEvent,
  SeverityFilter,
  SVGDefsSelection,
} from '@controlrooms/models';
import {
  appendFilters,
  appendGradients,
  buildCellsV2,
  buildErrors,
  buildGroupsV2,
  buildLabeledEventGroupsV2,
  buildNoData,
  setCellsVisibilityByClassName,
  TimeUtils,
} from '@controlrooms/utils';

dayjs.extend(utc);

// interface ZoomEvent {
//   transform:
//     | string
//     | number
//     | boolean
//     | readonly (string | number)[]
//     | d3.ValueFn<
//         d3.BaseType,
//         unknown,
//         string | number | boolean | readonly (string | number)[] | null
//       >
//     | null;
// }

export const Heatmap: React.FC<{
  folderId: number;
  heatmapData: InfiniteData<AnomaliesResult> | undefined;
  labeledEvents?: LabeledEvent[];
  interval: number;
  handleAnalyzeLink: () => void;
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  wideSpacing: boolean;
}> = ({ folderId, heatmapData, labeledEvents, interval, handleAnalyzeLink, wideSpacing }) => {
  // Heatmap data is an infinite result but currently referencing only page 0 (fetch prev/next not implemented)
  const chartData = heatmapData?.pages[0]?.result?.[folderId]?.anomalies;
  const limitsExceeded = heatmapData?.pages[0]?.result?.[folderId]?.limits_exceeded;

  const { viewId } = useViewContext();
  const { activeModes, activeView } = useLayoutContext();
  const selectedMode = activeModes[activeView];

  const { userRoles } = useRoles();
  const { user } = useAuth0();
  const { viewState } = useViewContext();

  const { currentTenant } = useTenants();
  const { track } = useAnalytics();
  const tenantConfig = currentTenant?.preferences;

  const { labelTypesFilter } = useMonitorFilteringContext();

  const {
    timeSelection: { startTime, endTime, timezone },
    view,
  } = viewState;

  const { severityFilter, showLimits } = view[selectedMode];

  const { groupTooltipData, setGroupTooltipData, setLabelTooltipData } = useContext(MonitorContext);

  const svgRef = useRef(null);
  const theme = useTheme();

  const { chartMargin } = monitorConfig;

  // store scrollable width
  const [height, setHeight] = useState<number>(500);

  const rowHeight = 24;

  const anomalyData = useMemo(() => {
    return chartData?.filter((a: Anomaly) => a.value > 0) ?? ([] as Array<Anomaly>);
  }, [chartData]);

  const errorData = useMemo(() => {
    return chartData?.filter((a: Anomaly) => a.value < 0) ?? ([] as Array<Anomaly>);
  }, [chartData]);

  useEffect(() => {
    const ChartWrapper = d3.select(`.heatmap-${viewId}[data-id="${folderId}"]`);

    if (!ChartWrapper) return;

    const { width: wrapperWidth, height: wrapperHeight } =
      (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect() ?? {};
    const ctrWidth = wrapperWidth - chartMargin.left;

    setHeight(rowHeight);

    const svgEl = d3.select(svgRef.current).attr('viewBox', `0 0 ${wrapperWidth} ${wrapperHeight}`);

    // Clear svg content before adding new elements
    svgEl.selectAll('*').remove();
    // add Tooltip container
    svgEl
      .append('rect')
      .attr('width', ctrWidth)
      .attr('height', wrapperHeight)
      .style('transform', `translate(${chartMargin.left}px, 0)`)
      .style('opacity', 0)
      .on('mouseout', () =>
        // on mouse out hide line, circles and text
        handleTooltipMouseOut('monitor', viewId),
      )
      .on('mouseover', () =>
        // on mouse in show line, circles and text
        handleTooltipMouseOver('monitor', { folder: folderId }),
      )
      .on('touchmouse mousemove', (e: MouseEvent) => {
        handleTooltipMouseMove('monitor', e, xScale, timezone, theme, { tenantConfig, viewId });
      })
      .classed(`heatmap-chart-rect-${viewId}`, true);

    const chart = svgEl
      .append('g')
      .attr('width', '100%')
      .attr('height', height)
      .attr('preserveAspectRatio', 'none')
      .attr('viewBox', `0 0 ${wrapperWidth} ${height}`);

    chart.style('transform', 'translate(0px, 2px)');

    if (wideSpacing) chart.style('transform', 'translate(0px, 18px)');

    const xScale = d3
      .scaleUtc()
      .domain([startTime.valueOf(), endTime.valueOf()])
      .range([0, ctrWidth]);

    // Moved this condition to show heatmap timeline for non anomalies system
    if ((!chartData || chartData.length === 0) && (!limitsExceeded || limitsExceeded.length === 0))
      return;

    const svgDefs = svgEl.append('defs') as unknown as SVGDefsSelection;

    // add Gradients to svg defs
    appendGradients(svgDefs, theme);

    // add filters to svg defs - drop shadows
    appendFilters(svgDefs);

    // remove all tooltips
    ChartWrapper.selectAll('.tooltip-content').remove();

    // add tooltip
    ChartWrapper.append('div')
      .datum(chartData)
      .attr('class', 'tooltip-content')
      .attr('id', `tooltip-content-${folderId}`);

    // Clipping
    svgDefs
      .append('clipPath')
      .attr('id', `clip-${viewId}`)
      .append('rect')
      .attr('x', chartMargin.left)
      .attr('y', 0)
      .attr('width', ctrWidth)
      .attr('height', wrapperHeight);

    // append a container for margin spacing
    const ctr = chart
      .append('g')
      .classed('folder-container ctr', true)
      .attr('transform', `translate(${chartMargin.left},0)`)
      .on('mouseenter', () => d3.select(`.folder-row-${folderId}`).classed('hover', true))
      .on('mouseleave', () =>
        d3.select(`.folder-row-${folderId}`).classed('hover', false),
      ) as unknown as SVGDefsSelection;

    buildCellsV2(ctr, anomalyData, xScale, tenantConfig.monitorAnomalyThresholds, {
      theme,
      height: rowHeight - 8,
      interval,
      rowHeight,
      limitData: limitsExceeded,
      labelTypesFilter,
      labeledEvents,
      showMonitorLimits: showLimits,
      showAnalyzeLimits: showLimits,
      folderId,
    }).attr('data-time', (d) => TimeUtils.toUtc(d.time).format('HH:mm'));

    //After building cells, use CSS to hide them based on the setting of the Severity Filter
    switch (severityFilter) {
      case SeverityFilter.LOW: {
        setCellsVisibilityByClassName(ctr, 'g1', 'block');
        setCellsVisibilityByClassName(ctr, 'g2', 'block');
        setCellsVisibilityByClassName(ctr, 'g3', 'block');
        break;
      }
      case SeverityFilter.MEDIUM: {
        setCellsVisibilityByClassName(ctr, 'g1', 'none');
        setCellsVisibilityByClassName(ctr, 'g2', 'block');
        setCellsVisibilityByClassName(ctr, 'g3', 'block');
        break;
      }
      case SeverityFilter.HIGH: {
        setCellsVisibilityByClassName(ctr, 'g1', 'none');
        setCellsVisibilityByClassName(ctr, 'g2', 'none');
        setCellsVisibilityByClassName(ctr, 'g3', 'block');
        break;
      }
    }

    if (user?.email_verified && userRoles.includes(Role.GLOBAL_LABEL_EDITOR)) {
      buildGroupsV2(ctr, anomalyData, xScale, folderId, {
        height: rowHeight - 8,
        interval,
        rowHeight,
        groupTooltipData,
        setGroupTooltipData,
        theme,
      });
    }

    const getFolderEvents = () => {
      const thisFolderEvents: LabeledEvent[] = [];
      labeledEvents?.forEach((le) => {
        if (le.process_id === folderId) {
          thisFolderEvents.push(le);
        }
      });
      return thisFolderEvents;
    };

    buildLabeledEventGroupsV2(ctr, getFolderEvents(), xScale, folderId, {
      theme,
      height,
      rowHeight,
      setGroupTooltipData,
      setLabelTooltipData,
    });

    const errorContainer = ctr.append('g').classed('error-container', true);
    buildNoData(errorContainer, errorData, folderId, xScale, tenantConfig, {
      errorHeight: 8,
      interval,
      noDataHeight: 2,
      rowHeight,
    });

    buildErrors(errorContainer, errorData, folderId, xScale, tenantConfig, {
      errorHeight: 12,
    });
  }, [
    wideSpacing,
    anomalyData,
    chartData,
    limitsExceeded,
    chartMargin.left,
    chartMargin.right,
    endTime,
    errorData,
    folderId,
    height,
    interval,
    startTime,
    tenantConfig,
    theme,
    timezone,
    rowHeight,
    groupTooltipData,
    setGroupTooltipData,
    setLabelTooltipData,
    severityFilter,
    labeledEvents,
    labelTypesFilter,
    showLimits,
    user,
    userRoles,
    viewId,
  ]);

  return (
    <StyledChart
      className={`heatmap-${viewId}`}
      data-id={folderId}
      onClick={() => {
        track('Monitor - Go to Analyze via Heatmap', {
          heatmap: 'clicked',
          folderId: folderId,
        });
        handleAnalyzeLink();
      }}
      onMouseOver={AnalyzeV2.preload}
    >
      {/* Comment out this condition to show heatmap to non anomalies system */}
      {/* {chartData || limitsExceeded ? <svg ref={svgRef} /> : <div className="empty" />} */}
      <svg ref={svgRef} />
    </StyledChart>
  );
};
