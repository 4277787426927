import { TimeRangeUtil } from '../utils/time';

import Request from './request';

import {
  AnomalousTagsPayload,
  AnomalousTagsResult,
  ApiListResult,
  ApiResult,
  ApiTimeServicePayload,
  AutoLabeledEventsTag,
  CRFolderTags,
  DeleteHiddenTagsResult,
  Ensemble,
  EventSearchResult,
  FetchHiddenTagsResult,
  HiddenTag,
  HideTagsResult,
  ResampleTagsMinMaxResult,
  // SearchTagsMinMaxResult,
  TagResampleMinMaxPayload,
  // TagSearchMinMaxPayload,
  TagStateResult,
  TimeSearchPayload,
  TimeSearchResult,
} from '@controlrooms/models';

const SERVICE_ROOT = '/tag-explorer/api/v1';
const SERVICE_ROOT_V2 = '/tag-explorer/api/v2';

function buildStreamingPayload(payload: ApiTimeServicePayload) {
  const { preset, ...rest } = payload;
  let _payload = rest;
  if (preset) {
    // Update the start / end time based on the relative time selection
    _payload = {
      ..._payload,
      ...TimeRangeUtil.ofDuration(preset),
    };
  }
  return _payload;
}

export const hideTag = (hideTagData: { folder: number; tag: string }): Promise<HideTagsResult> => {
  const _payload = {
    folder: hideTagData.folder,
    tag_name: hideTagData.tag,
    visibility: 'private',
  };
  return Request.post(`user-customize/accounts-customize/hidden_tags`, _payload, {
    timeout: 15000,
  }).then((r) => new HideTagsResult(r));
};

export const unHideTag = (tagPayload: HiddenTag): Promise<DeleteHiddenTagsResult> => {
  const { tag, folder } = tagPayload;
  return Request.delete(`user-customize/accounts-customize/hidden_tags/${tag}?folder=${folder}`, {
    timeout: 15000,
  }).then((r) => new DeleteHiddenTagsResult(r));
};

export const fetchAllHiddenTags = async (): Promise<FetchHiddenTagsResult> => {
  return Request.get(`user-customize/accounts-customize/hidden_tags`, {
    timeout: 15000,
  }).then((r) => new FetchHiddenTagsResult(r));
};

// export const searchTagsMinMax = (
//   payload: TagSearchMinMaxPayload,
// ): Promise<SearchTagsMinMaxResult> => {
//   const _payload = buildStreamingPayload(payload);
//   return Request.post(`${SERVICE_ROOT}/tags/search/resampled-minmax`, _payload, {
//     timeout: 15000,
//   }).then((r) => new SearchTagsMinMaxResult(r));
// };

export const resampledTagsMinMax = (
  payload: TagResampleMinMaxPayload,
  ensembleFamilyId: string,
): Promise<ResampleTagsMinMaxResult> => {
  const _payload = buildStreamingPayload(payload);
  return Request.post(
    `${SERVICE_ROOT}/tags/resampled_minmax_tags${
      ensembleFamilyId ? `?ensemble=${ensembleFamilyId}` : ''
    }`,
    _payload,
    {
      timeout: 15000,
    },
  ).then((r) => new ResampleTagsMinMaxResult(r));
};

export const getAutoLabeledEvents = (
  payload: TagResampleMinMaxPayload,
): Promise<ApiResult<AutoLabeledEventsTag[]>> => {
  const _payload = buildStreamingPayload(payload);
  return Request.post(`${SERVICE_ROOT_V2}/label_events/tags`, _payload, {
    timeout: 15000,
  });
};

export const resampledTagsMinMaxString = (
  payload: TagResampleMinMaxPayload,
  ensembleFamilyId: string,
): Promise<ResampleTagsMinMaxResult> => {
  const _payload = buildStreamingPayload(payload);
  return Request.post(
    `${SERVICE_ROOT_V2}/tags/resampled_minmax_string_tags${
      ensembleFamilyId ? `?ensemble=${ensembleFamilyId}` : ''
    }`,
    _payload,
    {
      timeout: 15000,
    },
  ).then((r) => new ResampleTagsMinMaxResult(r));
};

export const resampledTagsMinMaxDiscrete = (
  payload: TagResampleMinMaxPayload,
  ensembleFamilyId: string,
): Promise<ResampleTagsMinMaxResult> => {
  const _payload = buildStreamingPayload(payload);
  return Request.post(
    `${SERVICE_ROOT_V2}/tags/resampled_minmax_discrete_tags${
      ensembleFamilyId ? `?ensemble=${ensembleFamilyId}` : ''
    }`,
    _payload,
    {
      timeout: 15000,
    },
  ).then((r) => new ResampleTagsMinMaxResult(r));
};

export const getTagStates = (payload: {
  tag_name: string;
  infra_node_id: number;
}): Promise<TagStateResult> => {
  return Request.post(`${SERVICE_ROOT_V2}/tags/tag_states`, payload).then(
    (r) => new TagStateResult(r),
  );
};

export const anomalousTags = (
  payload: AnomalousTagsPayload,
  ensembleFamilyId: string,
): Promise<ApiListResult<CRFolderTags>> => {
  const _payload = buildStreamingPayload(payload);
  return Request.post(
    `${SERVICE_ROOT}/anomalous_tags_in_order${
      ensembleFamilyId ? `?ensemble=${ensembleFamilyId}` : ''
    }`,
    _payload,
    { timeout: 15000 },
  ).then((r) => new AnomalousTagsResult(r));
};

export const timeSearch = (
  payload: TimeSearchPayload,
): Promise<ApiListResult<TimeSearchResult>> => {
  const SERVICE_ROOT = '/ml/api/v2/tagSearch';
  const _payload = {
    tag_name: payload.tag_name,
    tag_start_time: payload.startTime,
    tag_end_time: payload.endTime,
    search_start_time: payload.searchStartTime,
    search_end_time: payload.searchEndTime,
  };
  return Request.post(`${SERVICE_ROOT}`, _payload, { timeout: 120000 }).then(
    (r) => new EventSearchResult(r),
  );
};

export const getEnsembleModels = (): Promise<ApiResult<{ family_list: Ensemble[] }>> =>
  Request.get(`${SERVICE_ROOT_V2}/ensemble_family`);
