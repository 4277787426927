import { DefaultTheme } from 'styled-components';

import { exportModalLightTheme } from '../app/components/export-modal';
import { uploadInstructionsLightTheme } from '../app/components/upload-instructions-modal';

import { alertLightTheme } from './component-themes/alert-theme';
import { chartLightTheme } from './component-themes/chart-theme';
import { checkboxLightTheme } from './component-themes/checkbox-theme';
import { confirmEmailLightTheme } from './component-themes/confirm-email';
import { datePickerLightTheme } from './component-themes/date-picker-theme';
import { dateTimeRangePickerLightTheme } from './component-themes/date-time-range-picker-theme';
import { deleteDataModalLightTheme } from './component-themes/delete-data-modal-theme';
import { fileUploadModalLightTheme } from './component-themes/file-upload-theme';
import { headerV2Light } from './component-themes/header-theme';
import { headerLightThemeSystem } from './component-themes/header-theme-system';
import { headerLightThemeTag } from './component-themes/header-theme-tag';
import { heatmapLightTheme } from './component-themes/heatmap-theme';
import { investigateLightTheme } from './component-themes/investigate-theme';
import { knobLightTheme } from './component-themes/knob-theme';
import { limitLightTheme } from './component-themes/limit-theme';
import { modalLightTheme } from './component-themes/modal-theme';
import { radioButtonLightTheme } from './component-themes/radio-button-theme';
import { scrollBarLightTheme } from './component-themes/scrollbar-theme';
import { searchLightTheme } from './component-themes/search-theme';
import { sidebarLightTheme, sidebarV2Light } from './component-themes/sidebar-theme';
import { sliderLightTheme } from './component-themes/slider-theme';
import { switchLightTheme } from './component-themes/switch-theme';
import { systemBrowserLightTheme } from './component-themes/system-browser-theme';
import { tabLightTheme } from './component-themes/tab-theme';
import { textInputLightTheme } from './component-themes/text-input-theme';
import {
  timeDisplayLightTheme,
  timeDisplayLightThemeV2,
} from './component-themes/timedisplay-theme';
import { timelineLightTheme } from './component-themes/timeline-theme';
import { timeSearchLightTheme } from './component-themes/timesearch-theme';
import { tooltipLightTheme } from './component-themes/tooltip-theme';
import { userPreferencesLightTheme } from './component-themes/user-preferences-theme';

import { selectLightTheme } from '@controlrooms/components';
import { buttonLightTheme } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const lightTheme: DefaultTheme = {
  name: 'Light',
  v2: {
    header: headerV2Light,
    sidebar: sidebarV2Light,
    timeDisplay: timeDisplayLightThemeV2,
    tab: tabLightTheme,
  },
  backgroundColor: colors.k[100],
  reportFilterbackgroundColor: colors.k[100],
  textColor: colors.k[0],
  linkColor: colors.focus.dark[3],
  navigation: {
    textColor: '#b6b6b6',
    borderColor: colors.k[100],
    active: {
      textColor: colors.k[100],
      borderColor: colors.k[100],
    },
  },
  button: buttonLightTheme,
  sidebar: sidebarLightTheme,
  chart: chartLightTheme,
  checkbox: checkboxLightTheme,
  datePicker: datePickerLightTheme,
  dateTimeRangePicker: dateTimeRangePickerLightTheme,
  deleteDataModal: deleteDataModalLightTheme,
  exportModal: exportModalLightTheme,
  tooltip: tooltipLightTheme,
  heatmap: heatmapLightTheme,
  modal: modalLightTheme,
  uploadInstructions: uploadInstructionsLightTheme,
  fileUploadModal: fileUploadModalLightTheme,
  radioButton: radioButtonLightTheme,
  timeDisplay: timeDisplayLightTheme,
  systemHeader: headerLightThemeSystem,
  select: selectLightTheme,
  tagHeader: headerLightThemeTag,
  switch: switchLightTheme,
  systemBrowser: systemBrowserLightTheme,
  scrollBar: scrollBarLightTheme,
  search: searchLightTheme,
  textInput: textInputLightTheme,
  confirmEmail: confirmEmailLightTheme,
  userPreferences: userPreferencesLightTheme,
  timeLine: timelineLightTheme,
  slider: sliderLightTheme,
  alert: alertLightTheme,
  timeSearch: timeSearchLightTheme,
  limit: limitLightTheme,
  knob: knobLightTheme,
  investigate: investigateLightTheme,
};
