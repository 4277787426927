import React, { useContext, useEffect, useState } from 'react';

import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useUserId } from '../../../app/hooks/accounts';
import { useAlertsAPI } from '../../../app/hooks/alerts';
import { AlertConditions } from '../../components/alert-modal/alert-form';
import AlertModal, { DurationTitle, SnoozeTitle } from '../../components/alert-modal/alert-modal';
import AlertsPreviewModal from '../../components/alert-modal/alerts-preview-modal';
import AlertSearch from '../../components/alert-search.tsx/alert-search';
import Sidebar from '../../components/sidebar';
import { WebhookModal } from '../../components/webhook-modal/webhook-modal';
import { LayoutProvider } from '../../context/layout-context';
import { ManageWebhooks, WebhookWrapper } from '../webhook/manage-webhook';

import {
  AlertCard,
  AlertContainer,
  AlertHeader,
  Heading,
  SubHeading,
  NoAlertCreatedWrap,
  HeadingWrapper,
  HeadingContainer,
  ShowAll,
  SearchHeading,
  AlertWrapper,
  ContentWrapper,
  AlertLayout,
} from './styles';

import { Button, Checkbox, Icon, Switch } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { Alert, SearchSuggestion } from '@controlrooms/models';

interface AlertCategory {
  [key: string]: Alert[];
}

export const alertConditionLabels: { [key in string]: string } = {
  anomalies_detected: 'Anomalies',
  iow_limit_exceeded: 'Limits',
};

export enum TabState {
  ALERT = 'ALERT',
  WEBHOOK = 'WEBHOOK',
}

const AlertPage: React.FC = () => {
  const [selectedAlertList, setSelectedAlertList] = useState<Alert[]>([]);
  const [hasNoAlert, setHasNoAlert] = useState(false);
  const [tabState, setTabState] = useState<TabState>(TabState.ALERT);
  const [disableAPILoading, setDisableAPILoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [alertCategory, setAlertCategory] = useState<AlertCategory>({
    views: [],
    systems: [],
    tags: [],
  });
  const [showAllAlertList, setShowAllAlertList] = useState({} as { [key: string]: boolean });
  const [selectedSearchResult, setSelectedSearchResult] = useState({} as SearchSuggestion);
  const { getAlertsMutation, updateAlertMutation, deleteMultipleAlertMutation } = useAlertsAPI();
  const { mutateAsync: getAlerts } = getAlertsMutation;
  const { mutateAsync: deleteMultipleAlert } = deleteMultipleAlertMutation;
  const { mutateAsync: updateAlert } = updateAlertMutation;
  const currentUserId = useUserId();
  const { userHasGlobalAlertEditRole, hasRole } = useContext(AuthorizationContext);

  useEffect(() => {
    getAlertsBySort();
    // eslint-disable-next-line
  }, [selectedSearchResult]);

  useEffect(() => {
    if (!openConfirmDelete) {
      setDeleteError('');
    }
  }, [openConfirmDelete]);

  const getAlertsBySort = async () => {
    try {
      setHasNoAlert(false);
      setSelectedAlertList([]);
      let queryString = `sort_by=scope`;
      if (selectedSearchResult.search_result) {
        queryString += `&filter_by=${selectedSearchResult.type}&filter_text=${selectedSearchResult.search_value}`;
      }
      const { result } = await getAlerts(queryString);
      if (!result?.alert_list.length) {
        setHasNoAlert(true);
      }
      categoriseAlert(result?.alert_list);
    } catch (error) {
      setHasNoAlert(false);
      console.log(error);
    }
  };

  const categoriseAlert = (alertList: Alert[]) => {
    const sortedAlert = { views: [], systems: [], tags: [] } as AlertCategory;
    alertList.map((alert) => {
      if (alert.target_type === 'view') {
        sortedAlert?.views.push(alert);
      } else if (alert.target_type === 'system') {
        sortedAlert?.systems.push(alert);
      } else if (alert.target_type === 'tag') {
        sortedAlert?.tags.push(alert);
      }
    });
    setAlertCategory({ ...sortedAlert });
  };

  const onDeleteAlert = async () => {
    try {
      setDeleteError('');
      const ids = selectedAlertList?.map((alert) => alert.id);
      await deleteMultipleAlert(ids as number[]);
      setOpenConfirmDelete(false);
      setSelectedAlertList([]);
      getAlertsBySort();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setDeleteError('Error occurred while delete. Please try again.');
      console.log(error);
    }
  };

  const handleShowAll = (targetType: string) => {
    setShowAllAlertList({ ...showAllAlertList, [targetType]: !showAllAlertList[targetType] });
  };

  const handleActiveClick = async (selectedAlert: Alert, selected: boolean) => {
    try {
      selectedAlert.disabled = !selected;
      setDisableAPILoading(true);
      await updateAlert({ ...selectedAlert, disabled: !selected });
      setAlertCategory({ ...alertCategory });
      setDisableAPILoading(false);
    } catch (error) {
      selectedAlert.disabled = selected;
      setDisableAPILoading(false);
      console.log(error, disableAPILoading);
    }
  };

  const handleSelectAllAlert = (targetType: string) => {
    const partialSelected =
      alertCategory[targetType]?.some((alert: Alert) => !alert.isSelected) &&
      alertCategory[targetType]?.some((alert: Alert) => alert.isSelected);
    const allSelected = alertCategory[targetType]?.every((alert: Alert) => alert.isSelected);

    const filteredAlerts = alertCategory[targetType]?.filter(
      (alert) => alert.user_id == currentUserId || userHasGlobalAlertEditRole,
    );

    filteredAlerts?.forEach((alert) => {
      alert.isSelected = partialSelected ? false : allSelected ? false : true;
    });

    const selectedAlerts = selectedAlertList
      .filter((alert) => alert.target_type !== targetType.slice(0, -1))
      .concat(partialSelected || allSelected ? [] : filteredAlerts);

    setAlertCategory({ ...alertCategory });
    setSelectedAlertList([...selectedAlerts]);
  };

  const handleSelectAlert = (selectedAlert: Alert) => {
    selectedAlert.isSelected = !selectedAlert.isSelected;
    setAlertCategory({ ...alertCategory });

    const findAlertIndex = selectedAlertList.findIndex((alert) => alert.id === selectedAlert.id);

    if (findAlertIndex !== -1) {
      selectedAlertList.splice(findAlertIndex, 1);
    } else {
      selectedAlertList.push(selectedAlert);
    }

    setSelectedAlertList([...selectedAlertList]);
  };

  const getSearchHeading = () => {
    const { category, search_result, type } = selectedSearchResult;
    if (!search_result) {
      return;
    }

    switch (category) {
      case 'created_by':
        return (
          <>
            Alerts created by <b>{search_result}</b>.
          </>
        );

      case 'recipient':
        return (
          <>
            Alerts with <b>{search_result}</b> as a Recipient.
          </>
        );

      case 'alert_name':
        return (
          <>
            Alert on the<span style={{ textTransform: 'capitalize' }}> {type}</span> named{' '}
            <b>{search_result}</b>.
          </>
        );

      default:
        return (
          <>
            Alerts containing <b>{search_result}</b>.
          </>
        );
    }
  };

  const handleSearchClick = (result: SearchSuggestion) => {
    setSelectedSearchResult(result);
  };

  const renderAlertCards = (alerts: Alert[], targetType: string) => {
    return (
      <>
        <AlertHeader>
          <span style={{ width: '24px', padding: '0 12px' }}>
            <Checkbox
              className="alert-checkbox"
              dataTestId={`select-all-alerts-checkboxes-${targetType}`}
              checked={
                alertCategory[targetType]?.find((alert: Alert) => !alert.isSelected) ? false : true
              }
              indeterminate={
                alertCategory[targetType]?.some((alert: Alert) => !alert.isSelected) &&
                alertCategory[targetType]?.some((alert: Alert) => alert.isSelected)
              }
              onChange={() => {
                handleSelectAllAlert(targetType);
              }}
            />
          </span>
          <span style={{ width: '240px', padding: '0 12px' }}>
            Alert name <Icon name={ICONS.ArrowDown} style={{ paddingLeft: '4px' }} />
          </span>
          <span style={{ width: '58px', padding: '0 12px' }}>Type</span>
          <span style={{ width: '58px', padding: '0 12px' }}>Exceedance</span>
          <span style={{ width: '58px', padding: '0 12px' }}>
            <DurationTitle />
          </span>
          <span style={{ width: '120px', padding: '0 12px' }}>
            <SnoozeTitle />
          </span>
          <span style={{ width: '440px', padding: '0 12px' }}>Recipients</span>
          <span style={{ width: '92px', padding: '0 12px' }}>Created by</span>
          <span style={{ width: '42px', padding: '0 12px' }}>Active</span>
        </AlertHeader>
        {alerts.map((alert, key) => {
          const hasEmailDestination = alert.alert_destination?.email_destination?.emails.length > 0;
          const hasTeamsDestination =
            alert.alert_destination?.teams_destination?.teams_channels?.length > 0;
          if (key > 4 && !showAllAlertList[targetType]) {
            return;
          }
          return (
            <AlertModal
              alertList={[alert]}
              targetId={alert?.target_id}
              targetType={alert?.target_type}
              onSuccessCallback={getAlertsBySort}
              key={alert?.id}
            >
              <AlertCard
                key={alert?.id}
                isActive={!alert.disabled || false}
                onClick={() => {
                  // handleSelectAlert(alert);
                }}
              >
                <span
                  style={{ width: '24px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="content"
                >
                  <Checkbox
                    className="alert-checkbox"
                    checked={alert?.isSelected ? true : false}
                    dataTestId={`${alert?.target_name}-${targetType}-checkbox`}
                    onChange={() => {
                      handleSelectAlert(alert);
                    }}
                    disabled={!(alert.user_id == currentUserId || userHasGlobalAlertEditRole)}
                  />
                </span>
                <span className="alert-name" style={{ width: '240px' }}>
                  {alert?.target_system && <span className="content">{alert?.target_system}</span>}
                  {alert?.target_type === 'tag' ? (
                    <span className="target-name" data-testid={`${alert?.target_name}-alerts`}>
                      <span className="target-id">{alert?.target_display_name}</span>
                      {alert?.tag_description || ''}
                    </span>
                  ) : (
                    <span className="target-name" data-testid={`${alert.target_name}-alerts`}>
                      {alert?.target_display_name ? alert?.target_display_name : 'No Name'}
                    </span>
                  )}
                </span>
                <div className="conditions" style={{ width: '386px' }}>
                  {Object.entries(alert.alert_conditions).map(([key, alertCondition]) => (
                    <React.Fragment key={key}>
                      {alertCondition?.selected && (
                        <div key={key} style={{ display: 'flex' }}>
                          <span
                            className="content"
                            style={{ width: '58px' }}
                            data-testid={`${key}-${alert.target_name}-type`}
                          >
                            {alertConditionLabels[key] || ''}
                          </span>
                          <span
                            className="content"
                            style={{ width: '58px', textAlign: 'right' }}
                            data-testid={`${key}-exceedance-${alert.target_name}`}
                          >
                            {key === AlertConditions.ANOMALIES_DETECTED
                              ? alertCondition?.anomaly_duration
                                ? `${alertCondition?.anomaly_duration} min`
                                : ''
                              : alertCondition?.exceedance_duration
                              ? `${alertCondition?.exceedance_duration} min`
                              : ''}
                          </span>
                          <span
                            className="content"
                            style={{ width: '58px', textAlign: 'right' }}
                            data-testid={`${key}-duration-${alert.target_name}`}
                          >
                            {alertCondition?.duration ? `${alertCondition?.duration} min` : ''}
                          </span>
                          <span
                            className="content"
                            style={{ width: '120px', textAlign: 'right' }}
                            data-testid={`${key}-snooze-${alert.target_name}`}
                          >
                            {alertCondition?.snooze_duration
                              ? `${alertCondition?.snooze_duration} min`
                              : ''}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <span style={{ width: '440px' }} className="destination content">
                  {hasTeamsDestination ? (
                    <span className="list">
                      <Icon name={ICONS.Teams} disabled={true} />
                      <span
                        data-testid={`teams-channel-name-${alert.target_name}`}
                        className={`content ${
                          alert.alert_destination?.teams_destination.selected ? '' : 'disabled'
                        }`}
                        style={{ padding: '0' }}
                      >
                        {alert.alert_destination?.teams_destination?.teams_channels.map(
                          (teams_channel, index, array) =>
                            `${teams_channel?.channel_name}${
                              index === array.length - 1 ? '' : ', '
                            }`,
                        )}
                      </span>
                    </span>
                  ) : null}
                  {hasEmailDestination ? (
                    <span className="list">
                      <Icon name={ICONS.Envelope} />
                      <span
                        data-testid={`email-${alert.target_name}`}
                        className="content word-wrap"
                        style={{ padding: '0' }}
                      >
                        {alert.alert_destination?.email_destination?.emails.join(', ')}
                      </span>
                    </span>
                  ) : null}
                </span>
                <span
                  className="content word-wrap"
                  style={{ width: '92px' }}
                  data-testid={`created_by_email_${alert?.user_email}`}
                >
                  {alert?.user_email || ''}
                </span>
                <span
                  className="active-switch"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Switch
                    selected={!alert?.disabled}
                    onClick={(e, selected) => {
                      handleActiveClick(alert, selected);
                    }}
                    dataTestId={`alerts_${alert.target_name}`}
                  />
                  <Icon className="alert-edit" name={ICONS.Edit} />
                </span>
              </AlertCard>
            </AlertModal>
          );
        })}
      </>
    );
  };

  return (
    <LayoutProvider>
      <AlertLayout>
        <Sidebar />
        <ContentWrapper>
          {tabState === TabState.ALERT && (
            <>
              <HeadingContainer>
                <HeadingWrapper>
                  <Heading data-testid="header_manage_webhooks">Manage Alerts Settings</Heading>
                  <span style={{ display: 'flex', gap: '8px' }}>
                    {hasRole(Role.TEAMS_ADMIN) && (
                      <Button
                        buttonSize="small"
                        buttonType="secondary"
                        data-testid="manage-webhooks"
                        onClick={() => {
                          setTabState(TabState.WEBHOOK);
                        }}
                      >
                        Manage Webhooks
                      </Button>
                    )}
                    <AlertModal
                      alertList={selectedAlertList}
                      multipleEdit={selectedAlertList.length > 1 ? true : false}
                      onSuccessCallback={getAlertsBySort}
                    >
                      <Button
                        buttonSize="small"
                        buttonType="secondary"
                        data-testid="edit-multiple-alert"
                        iconName={ICONS.Edit}
                        disabled={!selectedAlertList.length}
                      >
                        {selectedAlertList.length <= 1 ? 'Edit' : 'Edit multiple'}
                      </Button>
                    </AlertModal>
                    <Button
                      buttonSize="small"
                      buttonType="secondary"
                      data-testid="delete-multiple-alert"
                      onClick={() => setOpenConfirmDelete(true)}
                      iconName={ICONS.Delete}
                      disabled={!selectedAlertList.length}
                    >
                      Delete
                    </Button>
                    <AlertsPreviewModal
                      isAlertsPreviewOpen={openConfirmDelete}
                      setIsAlertsPreviewOpen={setOpenConfirmDelete}
                      alertList={selectedAlertList}
                      confirmDeleteCallback={onDeleteAlert}
                      isDeletePreview={true}
                      error={deleteError}
                    />
                    <AlertSearch handleSearchClick={handleSearchClick} />
                  </span>
                </HeadingWrapper>
              </HeadingContainer>
              <AlertContainer>
                <AlertWrapper>
                  {selectedSearchResult.search_result && (
                    <SearchHeading>
                      <h3 className="search-heading">{getSearchHeading()}</h3>
                      <div onClick={() => setSelectedSearchResult({ search_result: '' })}>
                        Go back to all Alerts.
                      </div>
                    </SearchHeading>
                  )}
                  {Object.entries(alertCategory).map(([key, alerts]) => (
                    <div key={key}>
                      {alerts.length ? (
                        <>
                          <SubHeading data-testid={`${key.toLowerCase() + '_title_locator'}`}>
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </SubHeading>
                          {renderAlertCards(alerts, key)}
                          <ShowAll showAll={showAllAlertList[key]}>
                            <Icon name={ICONS.Arrow} />
                            <span
                              onClick={() => {
                                handleShowAll(key);
                              }}
                            >
                              Show {showAllAlertList[key] ? 'limited' : 'all'}{' '}
                              {`${key.charAt(0).toUpperCase()}${key.slice(1)}`} alerts
                            </span>
                          </ShowAll>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                  {hasNoAlert && (
                    <NoAlertCreatedWrap id="no-data">
                      <div className="text-wrap">
                        <h1>No alerts created</h1>
                      </div>
                    </NoAlertCreatedWrap>
                  )}
                </AlertWrapper>
              </AlertContainer>
            </>
          )}
          {tabState === TabState.WEBHOOK && (
            <>
              <HeadingContainer>
                <HeadingWrapper>
                  <Heading>Manage Webhooks</Heading>
                  <span style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      data-testid="go-back-button"
                      buttonSize="small"
                      buttonType="secondary"
                      onClick={() => {
                        setTabState(TabState.ALERT);
                      }}
                    >
                      Go back
                    </Button>
                    {hasRole(Role.TEAMS_ADMIN) && (
                      <WebhookModal>
                        <Button
                          data-testid="add-outgoing-webhook-button"
                          buttonSize="small"
                          buttonType="primary"
                        >
                          Add outgoing webhook
                        </Button>
                      </WebhookModal>
                    )}
                  </span>
                </HeadingWrapper>
              </HeadingContainer>
              <AlertContainer>
                <WebhookWrapper data-testId="webhook_menu">
                  <ManageWebhooks onDeleteCallBack={getAlertsBySort} />
                </WebhookWrapper>
              </AlertContainer>
            </>
          )}
        </ContentWrapper>
      </AlertLayout>
    </LayoutProvider>
  );
};

export default AlertPage;
